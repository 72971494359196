<template>
  <v-hover v-slot:default="{ hover }" open-delay="150">
    <v-card
      :elevation="hover ? 16 : 2"
      max-width="374"
      router
      :to="{ name: 'interviews.live', params: { id: interview.id } }"
    >
      <!-- <pre>
      {{interview.vacancy}}
    </pre> -->
      <v-system-bar window color="white">
        <v-spacer></v-spacer>
        <v-alert
          dense
          outlined
          :color="`${status.color}`"
          class="py-0 mb-0 caption text-uppercase text-center"
          width="100px"
          >{{ $t(status.text) }}</v-alert
        >
      </v-system-bar>

      <v-list-item two-line class="">
        <v-list-item-content>
          <!-- <v-list-item-subtitle class="caption">Entidade</v-list-item-subtitle> -->
          <v-list-item-title class="text-h6">
            {{ interview.entity ? interview.entity.name : "" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-list-item two-line class="" style="margin-top: -5%">
        <v-list-item-content>
          <v-list-item-subtitle class="caption font-weight-medium">{{
            $t("Job_title")
          }}</v-list-item-subtitle>
          <v-list-item-title class="subtitle black--text">
            {{ interview.vacancy ? interview.vacancy.title : "" }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider class="mx-4"></v-divider>

      <v-card-text>
        <v-chip-group active-class="amber darken-1 white--text" column>
          <v-chip small>{{ dateTime }}</v-chip>
          <v-spacer></v-spacer>
          <v-btn icon color="error">
            <v-icon>mdi-video-outline</v-icon>
          </v-btn>
        </v-chip-group>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
import statusMixins from "@/mixins/status";
import { mapGetters } from "vuex";
import { format } from "date-fns";
import { pt, enUS } from "date-fns/locale";
export default {
  name: "InterviewCard",
  mixins: [statusMixins],
  props: {
    interview: Object,
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
    status() {
      const CANDIDATE = this.interview.candidates.find(
        (findCandidate) =>
          findCandidate.interviewee.id === this.currentUser.candidate.id
      );

      const CURRENT_STAGE = CANDIDATE.stages.find(
        (findStage) => findStage.name === CANDIDATE.currentStage
      );

      const PENDING_STATUS = { text: this.$t("pending"), color: "cyan" };
      const ONGOING_STATUS = { text: this.$t("ongoing"), color: "warning" };
      const CANCELED_STATUS = { text: this.$t("canceled"), color: "error" };
      const FINISHED_STATUS = { text: this.$t("finished"), color: "success" };

      switch (CURRENT_STAGE.state) {
        case "pending":
          return PENDING_STATUS;

        case "ongoing":
        case "pause":
          return ONGOING_STATUS;

        case "canceled":
          return CANCELED_STATUS;

        default:
          FINISHED_STATUS;
          break;
      }

      if (CURRENT_STAGE.state === "pending") {
        return PENDING_STATUS;
      } else if (
        CURRENT_STAGE.state === "ongoing" ||
        CURRENT_STAGE === "pause"
      ) {
        return ONGOING_STATUS;
      } else if (CURRENT_STAGE.state === "canceled") {
        return CANCELED_STATUS;
      } else {
        return FINISHED_STATUS;
      }
    },

    dateTime() {
      const CURRENT_LANG = this.$i18n.locale;
      const CANDIDATE = this.interview.candidates.find(
        (findCandidate) =>
          findCandidate.interviewee.id === this.currentUser.candidate.id
      );

      const CURRENT_STAGE = CANDIDATE.stages.find(
        (findStage) => findStage.name === CANDIDATE.currentStage
      );

      let formattedDate;
      switch (CURRENT_LANG) {
        case "en":
          formattedDate = format(
            new Date(`${CURRENT_STAGE.date} ${CURRENT_STAGE.time}`),
            "yyyy/MM/dd hh:mm a",
            { locale: enUS }
          );
          break;

        default:
          formattedDate = format(
            new Date(`${CURRENT_STAGE.date} ${CURRENT_STAGE.time}`),
            "dd/MM/yyyy HH:mm",
            { locale: pt }
          );
          break;
      }

      return formattedDate;
    },
  },

  methods: {},
};
</script>