import gql from "graphql-tag";

export const GET_INTERVIEWS_CANDIDATE_QUERY = gql`
  query GetInterviewsCandidate {
    interviews: getInterviewsCandidate {
      id
      candidacies {
        date
        status
        time
      }
      entity {
        name
      }
      vacancy {
        id
        title
      }
    }
  }
`;

export const GET_INTERVIEWS_BY_CANDIDATE_QUERY = gql`
  query getInterviewsByCandidate {
    interviews: getInterviewsByCandidate {
      id
      candidates {
        interviewee {
          id
        }
        currentStage
        stages {
          name
          date
          time
          state
        }
      }
      entity {
        name
      }
      vacancy {
        id
        title
        client {
          name
        }
      }
    }
  }
`;
