<template>
  <div>
    <v-row class="px-0">
      <v-col cols="12" md="7">
        <div class="text-left subtitle black--text" style="margin-top: 2%">
          {{ $t("interviews") }}
        </div>
      </v-col>
      <v-col cols="12" md="1" class="text-right px-6">
        <!-- <v-btn icon color="primary" @click="infoDialog = true">
          <v-icon>info</v-icon>
        </v-btn> -->
      </v-col>
      <v-col cols="12" md="4" class="text-right">
        <div class="text-right px-0 mx-6" style="width: 93.8%">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('cand_search_entity')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row>
    <v-divider class="my-3"></v-divider>
    <!-- <pre>
      {{filteredItems}}
    </pre> -->
    <interview-list :interviews="filteredItems" />
  </div>
</template>

<script>
import InterviewList from "../components/InterviewList.vue";
import { GET_INTERVIEWS_BY_CANDIDATE_QUERY } from "../graphql/Query";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  components: { InterviewList },
  name: "Interview",
  mixins: [replaceSpecialCharsMixins],
  data: () => ({
    interviews: [],
    textSearch: undefined,
  }),
  apollo: {
    interviews: {
      query: GET_INTERVIEWS_BY_CANDIDATE_QUERY,
    },
  },
  computed: {
    filteredItems() {
      let resultItems = [...this.interviews];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) => {
          if (t.entity && t.vacancy) {
            return (
              this.replaceSpecialChars(t.entity.name).includes(text) ||
              this.replaceSpecialChars(t.vacancy.title).includes(text)
            );
          } else if (t.entity) {
            return this.replaceSpecialChars(t.entity.name).includes(text);
          } else if (t.vacancy) {
            return this.replaceSpecialChars(t.vacancy.title).includes(text);
          }
        });
      }
      return resultItems;
    },
  },
};
</script>